<template>
<div>bar chart</div>
</template>


 
<script>
  export default {
    data () {
      return {
        
      }
    }
  }
</script>
