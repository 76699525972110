<template>
<div>line chart </div>
</template>


<script>
  export default {
    data () {
      return {
        
      }
    },
  }
</script>